import axios from 'axios';
import config from '../config';

let wa = new Object({
    fetchContactMessages: ctx => {
        return new Promise((resolve, reject) => {
            axios({
                url: config.apiEndPoint + '/wa/fetch',
                data: { ctx: ctx },
                method: 'POST',
            })
                .then(resp => {
                    resolve({
                        items: resp.data.responseData.messages,
                        totalCount: resp.data.responseData.count,
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    fetchTemplates: ctx => {
        return new Promise((resolve, reject) => {
            axios({
                url: config.apiEndPoint + '/wa/fetchTemplates',
                data: { ctx: ctx },
                method: 'POST',
            })
                .then(resp => {
                    resolve({
                        items: resp.data.responseData.templates
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    fetchPrograms: ctx => {
        return new Promise((resolve, reject) => {
            axios({
                url: config.apiEndPoint + '/wa/fetchPrograms',
                data: { ctx: ctx },
                method: 'POST',
            })
                .then(resp => {
                    resolve({
                        items: resp.data.responseData.programs
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    sendMessage: msg => {
        return new Promise((resolve, reject) => {
            axios({
                url: config.apiEndPoint + '/wa/sendMessage',
                data: { msg: msg },
                method: 'POST',
            })
                .then(resp => {
                    resolve(
                        resp.data.responseData
                    );
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
});

export default wa;
