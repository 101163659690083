<template>
    <v-dialog persistent overlay-opacity="0.6" max-width="700" :value="openDialog" @input="closeDialog()">
        <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
        <v-container fill-height fluid grid-list-xl>
            <v-layout style="max-height:800px" justify-center wrap>
                <v-flex md12>
                    <base-material-card class="pa-0" color="primary">
                        <template v-slot:heading>
                            <v-row class="pa-0">
                                <v-col cols="1" class="pa-0"><v-icon large>mdi-whatsapp</v-icon></v-col>
                                <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                                    $t("wa-message-title") }}</v-col>
                                <v-col cols="1" class="pa-0"><v-icon @click="closeDialog"
                                        large>mdi-close</v-icon></v-col>
                            </v-row>
                        </template>
                        <v-card-text>
                            <v-row class="pa-0">
                                <v-col align="center" cols="12" class="pa-0 mt-2" style="font-size: 16px"> {{
                                    contact ?
                                        contact.contact_phone : '' }}</v-col>

                            </v-row>
                            <v-row class="pa-0">
                                <v-col align="center" cols="12" class="pa-0 mt-2" style="font-size: 16px">
                                    <v-select :disabled="!socket" v-if="programs" v-model="selectedProgram"
                                        :items="programs" item-text="name" item-value="program_id" label="Program" dense
                                        outlined></v-select>
                                </v-col>

                            </v-row>
                            <v-row class="d-flex align-center mt-2" style="width: 100%;">

                            </v-row>
                        </v-card-text>

                        <v-card-text v-if="loading">
                            <v-progress-circular :indeterminate="true" :rotate="0" :size="32" :width="4"
                                color="light-blue"></v-progress-circular>

                        </v-card-text>

                        <v-card-text v-if="!socket && !loading">
                            <v-progress-circular :indeterminate="true" :rotate="0" :size="32" :width="4"
                                color="light-blue"></v-progress-circular> <v-row class="pa-0 mt-2">
                                <v-col align="center" cols="12" class="pa-0 mt-2" style="font-size: 16px"> {{
                                    'Disconnected. Please close the window to reload the chat...' }}</v-col>

                            </v-row>
                        </v-card-text>

                        <v-card-text v-if="!loading && socket">
                            <div class="scrollable-content overflow-auto" ref="scrollable" style="max-height: 400px;">

                                <div v-for="(message, index) in messages" :key="index" class="d-flex mb-3">
                                    <!-- User Bubble -->
                                    <div v-if="message.sent" class="ml-auto bubble user-bubble">
                                        {{ message.content }}
                                        {{ message.status >= 2 ? message.status >= 3 ? "✓✓" : "✓" : '' }}
                                    </div>
                                    <!-- Bot/Other Bubble -->
                                    <div v-else class="mr-auto bubble bot-bubble">
                                        {{ message.content }}
                                        {{ message.status >= 2 ? message.status >= 3 ? "✓✓" : "✓" : '' }}
                                    </div>
                                </div>


                            </div> <v-row class="d-flex align-center pa-4 mt-2" style="width: 100%;">
                                <v-text-field v-if="hasTimeframe" :disabled="sending" hide-details v-model="input"
                                    :placeholder="$t('wa-type-message')" outlined dense class=" ma-0 pa-0"
                                    style="flex: 1;" @keyup.enter="sendMessage"></v-text-field>
                                <v-text-field v-if="!hasTimeframe" hide-details v-model="input" readonly
                                    :disabled="sending" :placeholder="$t('wa-timeframe-expired')" outlined dense
                                    class="ma-0 pa-0" style="flex: 1;" @keyup.enter="sendMessage"></v-text-field>

                                <v-btn v-if="!sending && hasTimeframe" :disabled="input.length < 1" color="primary"
                                    class="ml-2" @click="sendMessage">
                                    {{ $t('wa-send') }}
                                </v-btn>
                                <v-menu v-model="menu" :close-on-content-click="true" offset-y top>
                                    <!-- Button that triggers the menu -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :disabled="sending" color="primary" dark v-bind="attrs" v-on="on">
                                            {{ $t('wa-template') }} </v-btn>
                                    </template>

                                    <!-- Menu content -->
                                    <v-card>
                                        <v-list>
                                            <v-list-item v-for="(item, index) in templates" :key="index"
                                                @click="sendTemplate(item)">
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                                <v-progress-circular class="ml-2" v-if="sending" :indeterminate="true" :rotate="0"
                                    :size="32" :width="4" color="light-blue"></v-progress-circular>
                            </v-row>
                        </v-card-text>
                    </base-material-card><!-- Chat Input -->

                </v-flex>
            </v-layout>
        </v-container>
    </v-dialog>
</template>

<script>

import waManager from "@/apis/wa"
import usersManager from "@/apis/users"
import config from "../config";
import { io } from "socket.io-client";

export default {
    data() {
        return {
            openDialog: false,
            contact: null, input: "", // User input
            messages: [],
            messagesCount: 0,
            templates: [],
            loading: false,
            menu: false,
            sending: false,
            socket: null,
            selectedProgram: null,
            programs: []
        };
    },

    computed: {
        hasTimeframe() {
            if (this.messages.length === 0) return false;
            let receivedMessages = this.messages.filter((message) => !message.sent);
            if (receivedMessages.length === 0) return false;
            let lastMessage = receivedMessages[0];
            if (lastMessage.timestamp - Date.now() > 24 * 60 * 60 * 1000) return false;
            return true;
        }
    },

    watch: {

        messages() {
            this.$nextTick(() => {
                this.scrollToBottom();
            });
            /*for (let x of this.messages) {
                if (x.status < 3 && !x.sent) {
                    if (this.socket) this.socket.send({ event: { contact_phone: this.contact.contact_phone, message_id: x.message_id, status: 3 } });
                }
            }*/
        },

    },

    methods: {
        scrollToBottom() {
            const scrollable = this.$refs.scrollable;
            if (scrollable) {
                scrollable.scrollTop = scrollable.scrollHeight;
            }
        },

        sendMessage() {
            if (this.input.trim() === "") return;
            this.sending = true;
            usersManager
                .keepAlive()
                .then(() => {
                    waManager.sendMessage({ contact_phone: this.contact.contact_phone, message: this.input, program_id: this.selectedProgram.program_id }).then((result) => {
                        this.messages.push({ content: this.input, sent: true, message_id: result, status: 1 });
                        this.input = "";
                        this.sending = false;
                    }).catch((error) => {
                        console.log(error);
                        this.input = "";
                        this.sending = false;
                    });
                }).catch((error) => {
                    console.log(error);
                    this.sending = false;
                });

        },

        sendTemplate(item) {
            this.sending = true;
            usersManager
                .keepAlive()
                .then(() => {
                    item.content = item.name;
                    for (let x of item.components) {
                        if (x.type == "BODY") {
                            item.content = x.text; break;
                        }
                    }

                    waManager.sendMessage({ contact_phone: this.contact.contact_phone, template: item, program_id: this.selectedProgram.program_id }).then((result) => {
                        this.messages.push({
                            timestamp: Date.now()
                            , content: item.content, sent: true
                            , message_id: result,
                            status: 1
                        });
                        this.sending = false;

                    }).catch((error) => {
                        console.log(error);
                        this.sending = false;
                    });
                }).catch((error) => {
                    console.log(error);
                    this.sending = false;
                });
        },

        onConnect() {
            if (this.socket) this.socket.send({ contact_phone: this.contact.contact_phone });

        },

        onDisconnect() {
            console.log("Disconnected");
            this.socket = null;
        },

        onWAEvents(data) {
            if (data.msg) {
                this.messages.push({ content: data.msg.message, sent: false, status: 3, message_id: data.msg.message_id });
                // Ack reading
                this.socket.send({ event: { contact_phone: this.contact.contact_phone, message_id: data.msg.message_id, status: 3 } });
            } else if (data.event) {
                let event = data.event;
                if ('+' + event.contact_phone == this.contact.contact_phone) {
                    let msg_id = event.message_id;
                    let message = this.messages.find((message) => message.message_id == msg_id);
                    if (message) {
                        if (message.status < event.status || !message.status) message.status = event.status;
                    }
                }
            }
        },

        show(item) {
            this.openDialog = true;
            this.contact = item;
            this.fetchMessages();
            this.socket = io(config.ioEndPoint, { path: '/ws', autoConnect: false });
            this.socket.on("connect", this.onConnect);
            this.socket.on("disconnect", this.onDisconnect);
            this.socket.on("wa", this.onWAEvents);
            this.socket.connect();
        },
        closeDialog() {
            this.openDialog = false;
            this.input = "";
            if (this.socket) this.socket.disconnect();
            this.resetModal();
        },

        resetModal() {
            this.contact = null;
            this.loading = false;
        },

        fetchMessages() {
            this.loading = true;
            waManager.fetchPrograms().then((response) => {

                this.programs = response.items;
                this.selectedProgram = this.programs[0];

                waManager.fetchTemplates({ program_id: this.selectedProgram.program_id }).then((response) => {
                    this.templates = response.items;
                    waManager.fetchContactMessages(({ program_id: this.selectedProgram.program_id, contact_phone: this.contact.contact_phone })).then((response) => {
                        this.messages = response.items;
                        this.messagesCount = response.totalCount;
                        this.loading = false;
                    }).catch((err) => {
                        console.log(err);
                        this.loading = false;
                    });
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });


        }
    }
};
</script>

<style scoped>
.chat-box {
    border-radius: 10px;
}

.bubble {
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
}

.user-bubble {
    background-color: #dcf8c6;
    /* WhatsApp user message green */
    color: #000;
}

.bot-bubble {
    background-color: #ececec;
    /* WhatsApp bot message gray */
    color: #000;
}
</style>